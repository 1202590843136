import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import './club.css';

const defaultImage = '/club.jpeg'; 
export default function Index() {
    const [clubData, setClubData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchClubData = async () => {
            try {
                const response = await axios.get('https://admin.enigmamotions.com/api/auth/get-club-list');
                setClubData(response.data.data);
            } catch (error) {
                console.error('Error fetching club data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchClubData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Container>
            <Row className='gx-2'>
                <Col>
                    <div className='pt-3 text-black'>
                        <h4 className="m-0">Clubs</h4>
                        <Row>
                            {clubData.map((club, index) => (
                                <Col md={4} key={index}>
                                    <div className="club-entry pt-3 pb-3 ps-4 pe-4">
                                        <p className="secondary m-0">{club.club_name}</p>
                                        <div className='borbot'></div>
                                        <div className=" pt-3 pb-3 ">  <img 
                                            src={club.club_image || defaultImage} 
                                            alt={club.club_name} 
                                            className="club-image " 
                                        /> </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
