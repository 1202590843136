import React from 'react'
import Discussioncontent from './Discussioncontent'
import Footer from '../../common/Footer'
import Copyright from '../../common/Copyright'

export default function index() {
    return (
        <>
            <Discussioncontent />
            <Footer />
            <Copyright />
        </>
    )
}
