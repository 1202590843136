import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import './forumcategory.css';

export default function Index() {
    const [catData, setCatData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCatData = async () => {
            try {
                const response = await axios.get('https://admin.enigmamotions.com/api/auth/get-form-category-list');
                setCatData(response.data.data);
            } catch (error) {
                console.error('Error fetching chat data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCatData();
    }, []);
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-GB', options);
    };
    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Container>
            <Row className='gx-2'>
                <Col>
                    {catData.map((cat, index) => (
                        <div key={index} className='pt-3 text-black'>
                            <h4 className="m-0">
                                <Link to={`/discussions/${cat.id}`} className="text-black">{cat.cat_name}</Link>
                            </h4>
                            <p className="secondary mb-3">Date: {formatDate(cat.created_at)}</p>
                        </div>
                    ))}
                </Col>
            </Row>
        </Container>
    );
}
