import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import './livecompletmatch.css';

const API_URL = 'https://admin.enigmamotions.com/api/auth/get-channel-list';
const MAX_RESULTS = 4;

// Utility function to chunk an array
const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
};

export default function Index() {
    const [videos, setVideos] = useState([]);
    const [nextPageToken, setNextPageToken] = useState(null);
    const [prevPageToken, setPrevPageToken] = useState(null);
    const [channelData, setChannelData] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState(null); 

    const fetchVideos = (pageToken = '') => {
        if (!selectedChannel) return; 
        const { channel_key, channel_id } = selectedChannel;
        fetch(`https://www.googleapis.com/youtube/v3/search?key=${channel_key}&channelId=${channel_id}&part=snippet,id&eventType=completed&type=video&order=date&maxResults=${MAX_RESULTS}&pageToken=${pageToken}`)
            .then(response => response.json())
            .then(data => {
                const videoData = data.items.map(item => ({
                    videoId: item.id.videoId,
                    title: item.snippet.title,
                    description: item.snippet.description,
                    thumbnail: item.snippet.thumbnails.high.url
                }));
                setVideos(videoData);
                setNextPageToken(data.nextPageToken || null);
                setPrevPageToken(data.prevPageToken || null);
            })
            .catch(error => console.error('Error fetching data from YouTube API:', error));
    };

    const fetchChannelData = async () => {
        try {
            const response = await axios.get(API_URL);
            const data = response.data.data;
            setChannelData(data); 
            if (data.length > 0) {
                setSelectedChannel(data[0]);
            }
        } catch (error) {
            console.error('Error fetching channel data:', error);
        }
    };

    useEffect(() => {
        fetchChannelData();
    }, []);

    useEffect(() => {
        if (selectedChannel) {
            fetchVideos();
        }
    }, [selectedChannel]);

    const handleChannelSelect = (channel) => {
        setSelectedChannel(channel);
    };

    // Chunk videos for carousel
    const chunkedVideos = chunkArray(videos, 4);

    return (
        <Container>
            <div>
                <h4>Select Channel</h4>
                <div className="channel-buttons">
                    {channelData.map((channel, index) => (
                        <Button
                            key={index}
                            variant={selectedChannel === channel ? "primary" : "outline-primary"}
                            onClick={() => handleChannelSelect(channel)}
                            style={{ marginRight: '10px', marginBottom: '10px' }}
                        >
                            {channel.channel_name}
                        </Button>
                    ))}
                </div>
            </div>
            <div className='pt-3'>
                <h4>Completed Live Match</h4>
                <Carousel>
                    {chunkedVideos.map((chunk, index) => (
                        <Carousel.Item key={index}>
                            <Row>
                                {chunk.map((video, idx) => (
                                    <Col lg={3} md={6} key={idx}>
                                        <div className="video-container">
                                            <iframe
                                                width="100%"
                                                height="auto"
                                                src={`https://www.youtube.com/embed/${video.videoId}`}
                                                title={video.title}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                            <h5>{video.title}</h5>
                                            <p>{video.description}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </Container>
    );
}
