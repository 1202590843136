import React from 'react'
import './logo.css'

export default function index() {
    return (
        <>
            <img src="/img/logo.png"
                className="logo" alt="logo" />
        </>
    )
}
