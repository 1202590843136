import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import axios from 'axios';

export default function Index() {
    const [topPicksData, setTopPicksData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTopPicksData = async () => {
            try {
                const limit = window.location.pathname === '/clubs' ? 'limit=500' : 'limit=12';
                const response = await axios.get(`https://admin.enigmamotions.com/api/auth/get-highlight-list?type=2&${limit}`);
                setTopPicksData(response.data.data);
            } catch (error) {
                console.error('Error fetching top picks data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTopPicksData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    const isYouTubeUrl = (url) => {
        return url.includes('youtu.be') || url.includes('youtube.com');
    };

    const getYouTubeEmbedUrl = (url) => {
        let videoId = '';
        if (url.includes('youtu.be')) {
            videoId = url.split('/').pop();
        } else {
            videoId = new URL(url).searchParams.get('v');
        }
        return `https://www.youtube.com/embed/${videoId}`;
    };

    // Chunking topPicksData into groups of 4 for Carousel display
    const chunkedPics = topPicksData.reduce((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index / 4); 
        
        if (!resultArray[chunkIndex]) { 
            resultArray[chunkIndex] = [];
        } 
        
        resultArray[chunkIndex].push(item); 
        return resultArray; 
    }, []);

    return (
        <Container>
            <div className='text-black pt-3'>
                <h4>Top Picks</h4>
                <Carousel>
                    {chunkedPics.map((chunk, index) => (
                        <Carousel.Item key={index}>
                            <Row>
                                {chunk.map((topPick, idx) => (
                                    <Col lg={3} md={6} key={idx}>
                                        {isYouTubeUrl(topPick.video_file) ? (
                                            <div className="embed-responsive embed-responsive-16by9">
                                                <iframe
                                                    className="embed-responsive-item"
                                                    src={getYouTubeEmbedUrl(topPick.video_file)}
                                                    title={topPick.video_title}
                                                    frameBorder='0'
                                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                        ) : (
                                            <video className="w-100" controls>
                                                <source src={topPick.video_file} type='video/mp4' />
                                                <source src={topPick.video_file} type='video/ogg' />
                                                Your browser does not support the video tag.
                                            </video>
                                        )}
                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </Container>
    );
}
