import React from 'react'
import Forumcategory from './Forumcategory'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import Copyright from '../../common/Copyright'

export default function index() {
    return (
        <>
            <Forumcategory />
            <Footer />
            <Copyright />
        </>
    )
}
