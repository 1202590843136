import React, { useState } from 'react';
import { Navigate,Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default function Login({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false); 
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);

      const response = await fetch('https://admin.enigmamotions.com/api/auth/login', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        onLogin(data);
        setLoggedIn(true); 
        alert('Login successfully!');
      } else {
        setError('Login failed'); 
      }
    } catch (error) {
      console.error('Error during login:', error);
      setError('An error occurred. Please try again.'); 
    }
  };

  if (loggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <Container>
      <div className="text-black p-3">
        <Row>
          <Col md={3}></Col>
          <Col md={6}>
            <h3 className="text-center mt-3 mb-3">Log In</h3>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="*******"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <div className="text-center mt-3">
                <Button type="submit" variant="primary">
                  Sign in
                </Button>
              </div>
              {error && <p className="text-danger mt-2">{error}</p>} {/* Display error message if login fails */}
            </Form>
            <div className="text-center mt-3">
              <p>
                Not a member?&nbsp;
              <Link to="/signup" className="text-decoration-none">
                Register
              </Link>
              </p>
            </div>
          </Col>
          <Col md={3}></Col>
        </Row>
      </div>
    </Container>
  );
}
