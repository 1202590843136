import React, { useState, useEffect } from 'react';
import './highlight.css';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import axios from 'axios';

export default function Index() {
    const [highlightsData, setHighlightsData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchHighlightData = async () => {
            try {
                const limit = window.location.pathname === '/clubs' ? 'limit=500' : 'limit=12';
                const response = await axios.get(`https://admin.enigmamotions.com/api/auth/get-highlight-list?type=1&${limit}`);
                setHighlightsData(response.data.data);
            } catch (error) {
                console.error('Error fetching highlight data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchHighlightData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    const isYouTubeUrl = (url) => {
        return url && (url.includes('youtu.be') || url.includes('youtube.com'));
    };

    const getYouTubeVideoId = (url) => {
        if (url.includes('youtu.be')) {
            return url.split('/').pop();
        } else {
            const params = new URL(url).searchParams;
            return params.get('v');
        }
    };

    const chunkedHighlights = highlightsData.reduce((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index / 4); 
        
        if (!resultArray[chunkIndex]) { 
            resultArray[chunkIndex] = [];
        } 
        
        resultArray[chunkIndex].push(item); 
        return resultArray; 
    }, []);

    return (
        <Container>
            <div className='text-black pt-3'>
                <h4>Highlights</h4>
                <Carousel>
                    {chunkedHighlights.map((chunk, index) => (
                        <Carousel.Item key={index}>
                            <Row>
                                {chunk.map((highlight) => (
                                    <Col lg={3} md={6} key={highlight.id} className='posrel'>
                                        <div className='posabs'>
                                            <h5 className='m-0'>{highlight.video_title}</h5>
                                            <p className="m-0">{highlight.video_description}</p>
                                        </div>
                                        {isYouTubeUrl(highlight.video_file) ? (
                                            <div className="embed-responsive embed-responsive-16by9">
                                                <iframe
                                                    title={highlight.video_title}
                                                    className="embed-responsive-item"
                                                    src={`https://www.youtube.com/embed/${getYouTubeVideoId(highlight.video_file)}`}
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                        ) : (
                                            <video className="w-100" controls>
                                                <source src={highlight.video_file} type='video/mp4' />
                                                Your browser does not support the video tag.
                                            </video>
                                        )}
                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </Container>
    );
}
