import React from 'react';
import Loginform from './Loginform';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import Copyright from '../../common/Copyright';

export default function Login({ onLogin }) {
    return (
        <>
            <Loginform onLogin={onLogin} />
            <Footer />
            <Copyright />
        </>
    );
}
