import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import './discussion.css';

export default function Index() {
    const [chatData, setChatData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [comment, setComment] = useState('');
    const [userId, setUserId] = useState(null);
    const { catId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const userToken = localStorage.getItem('user_id');
        if (userToken) {
            setUserId(userToken);
        }

        const fetchChatData = async () => {
            try {
                const response = await axios.get('https://admin.enigmamotions.com/api/auth/get-chat-list', {
                    params: { cat_id: catId }
                });
               
                setChatData(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching chat data:', error);
                setLoading(false);
            }
        };

        fetchChatData();
    }, [catId]); 

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-GB', options);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!userId) {
            navigate('/login');
            return;
        }
        try {
            const response = await axios.post('https://admin.enigmamotions.com/api/auth/create-chat', {
                user_id: userId,
                cat_id: catId,
                msg: comment
            });
            const newChatMessage = {
                id: response.data.data.id,
                user_id: response.data.data.user_id,
                cat_id: response.data.data.cat_id,
                msg: response.data.data.msg,
                name: response.data.data.name, 
                fc_date: response.data.data.created_at 
            };
            setChatData([...chatData, newChatMessage]);
            setComment('');
        } catch (error) {
            console.error('Error submitting comment:', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Container>
            <Row className='gx-2 '>
                <Col>
                    <div className='pt-3 text-black'>
                        {/* Display the first cat_name from the first item in chatData */}
                        {chatData.length > 0 && (
                            <h4 className="m-0">{chatData[0].cat_name}</h4>
                        )}
                        {/* Format fc_date to desired format */}
                        {chatData.length > 0 && (
                            <p className="secondary mb-3">{formatDate(chatData[0].cat_date)}</p>
                        )}
                        {chatData.map((chat, index) => (
                            <Row key={index} className='mt-4'>
                                <Col lg={1}>
                                    <img src="/img/profile.png" className="ms-lg-4 ms-0 profile-round" alt="profile" />
                                </Col>
                                <Col lg={11}>
                                    <h5 className="m-0">{chat.name}</h5>
                                    <p className="secondary p-0">{chat.msg}</p>
                                    <p className="secondary p-0">Date: {formatDate(chat.fc_date)}</p>
                                </Col>
                            </Row>
                        ))}
                        {userId ? (
                            <form onSubmit={handleSubmit}>
                                <div className="form-floating mt-3">
                                    <textarea
                                        className="form-control"
                                        placeholder="Leave a comment here"
                                        id="floatingTextarea"
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                        required
                                    ></textarea>
                                    <label htmlFor="floatingTextarea">Write Comments</label>
                                </div>
                                <Button variant="primary" type="submit" className="mt-2">Submit</Button>
                            </form>
                        ) : (
                            <p>Please <a href="/login">login</a> to submit a comment.</p>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
