import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Spinner, Carousel } from 'react-bootstrap';
import axios from 'axios';
import ReactPlayer from 'react-player';
import './livematch.css';

const API_URL = 'https://admin.enigmamotions.com/api/auth/get-channel-list';

export default function LiveMatch() {
    const [liveVideos, setLiveVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [quotaExceeded, setQuotaExceeded] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [sliderData, setSliderData] = useState([]);
    const [channelData, setChannelData] = useState([]);

    useEffect(() => {
        const fetchSliderData = async () => {
            try {
                const limit = 'limit=500';
                const response = await axios.get(`https://admin.enigmamotions.com/api/auth/get-highlight-list?type=1&${limit}`);
                setSliderData(response.data.data);
            } catch (error) {
                console.error('Error fetching highlight data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSliderData();
    }, []);

    useEffect(() => {
        const userToken = localStorage.getItem('user_id');
        if (userToken) {
            setIsLoggedIn(true);
        }
        fetchChannelData();
    }, []);

    useEffect(() => {
        if (channelData.length > 0) {
            fetchAllLiveStreams();
        }
    }, [channelData]);

    const fetchChannelData = async () => {
        try {
            const response = await axios.get(API_URL);
            const data = response.data.data;
            setChannelData(data);
        } catch (error) {
            console.error('Error fetching channel data:', error);
        }
    };

    const fetchAllLiveStreams = async () => {
        setLoading(true);
        let quotaExceededFlag = false;
        const promises = channelData.map(channel => fetchLiveStreams(channel.channel_key, channel.channel_id).catch(error => {
            if (error.message === 'Quota Exceeded') {
                quotaExceededFlag = true;
                return [];
            }
            throw error;
        }));

        try {
            const results = await Promise.all(promises);
            const combinedVideos = results.flat();
            setLiveVideos(combinedVideos);
            if (combinedVideos.length === 0 && quotaExceededFlag) {
                setQuotaExceeded(true);
            }
        } catch (error) {
            console.error('Error fetching live streams:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchLiveStreams = async (channelKey, channelId) => {
        try {
            const response = await axios.get('https://www.googleapis.com/youtube/v3/search', {
                params: {
                    part: 'snippet',
                    channelId: channelId,
                    eventType: 'live',
                    type: 'video',
                    key: channelKey
                }
            });
            if (response.data.items.length === 0) {
                console.warn(`No live streams found for channel ID: ${channelId}`);
                return [];
            } else {
                return response.data.items.map(item => ({
                    video_file: `https://www.youtube.com/watch?v=${item.id.videoId}`,
                    video_title: item.snippet.title,
                    video_description: item.snippet.description
                }));
            }
        } catch (error) {
            if (error.response && error.response.status === 403 && error.response.data.error.errors.some(e => e.reason === 'quotaExceeded')) {
                throw new Error('Quota Exceeded');
            } else {
                console.error('Error fetching live streams:', error);
                throw error;
            }
        }
    };

    const handleLoginClick = () => {
        alert('Please login to watch the live stream.');
    };

    const combinedVideos = liveVideos.length > 0 ? liveVideos : sliderData;

    return (
        <Container>
                <h4>Live Match</h4>
            <Row className="gx-4 mt-4">
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : quotaExceeded ? (
                    <Col lg={12} className='posrel'>
                        <video style={{ width: '100%' }} autoPlay loop muted>
                            <source src="img/video.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div className="posabs">
                            <h2 className="m-0">Quota Exceeded</h2>
                            <p className="secondary p-0">We have exceeded our API quota limit. Please try again later.</p>
                        </div>
                    </Col>
                ) : (
                    <Carousel>
                        {combinedVideos.map((video, index) => (
                            <Carousel.Item key={index}>
                                <Row>
                                    <Col lg={6} className='posrel'>
                                        <ReactPlayer 
                                            url={video.video_file}
                                            playing={true} 
                                            controls
                                            width='100%'
                                        />
                                        <Carousel.Caption>
                                            <h3>{video.video_title}</h3>
                                            <p>{video.video_description}</p>
                                            {isLoggedIn ? (
                                                <Button as="a" className='grey-btn' href={video.video_file} target="_blank">
                                                    Watch Live
                                                </Button>
                                            ) : (
                                                <Button className='grey-btn' onClick={handleLoginClick}>
                                                    Login to Watch
                                                </Button>
                                            )}
                                        </Carousel.Caption>
                                    </Col>
                                    {combinedVideos[index + 1] && (
                                        <Col lg={6} className='posrel d-none d-lg-block'>
                                            <ReactPlayer 
                                                url={combinedVideos[index + 1].video_file}
                                                playing={true} 
                                                controls
                                                width='100%'
                                            />
                                            <Carousel.Caption>
                                                <h3>{combinedVideos[index + 1].video_title}</h3>
                                                <p>{combinedVideos[index + 1].video_description}</p>
                                                {isLoggedIn ? (
                                                    <Button as="a" className='grey-btn' href={combinedVideos[index + 1].video_file} target="_blank">
                                                        Watch Live
                                                    </Button>
                                                ) : (
                                                    <Button className='grey-btn' onClick={handleLoginClick}>
                                                        Login to Watch
                                                    </Button>
                                                )}
                                            </Carousel.Caption>
                                        </Col>
                                    )}
                                </Row>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                )}
            </Row>
        </Container>
    );
}
