import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Navigate,Link } from 'react-router-dom';
import axios from 'axios';

export default function SignupForm() {
    const [user, setUser] = useState({
        name: "", email: "", password: "", c_password: ""
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const handleInputs = (e) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    };

    const validateForm = () => {
        const errors = {};
        if (!user.name.trim()) {
            errors.name = "Name is required";
        }
        if (!user.email.trim()) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(user.email)) {
            errors.email = "Email is invalid";
        }
        if (!user.password.trim()) {
            errors.password = "Password is required";
        }
        if (user.password !== user.c_password) {
            errors.c_password = "Passwords do not match";
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            setLoading(true);
            axios.post('https://admin.enigmamotions.com/api/auth/register', user)
                .then(response => {
                    alert('User Added'); 
                    setLoading(false);
                    window.location.href = '/login'; 
                })
                .catch(err => {
                    setLoading(false);
                    if (err.response && err.response.data && err.response.data.data) {
                        const serverErrors = err.response.data.data;
                        const formattedErrors = {};
                        Object.keys(serverErrors).forEach(key => {
                            formattedErrors[key] = serverErrors[key][0];
                        });
                        setErrors(formattedErrors);
                    } else {
                        console.error(err);
                        alert('Registration failed'); 
                    }
                });
        }
    };

    return (
        <Container>
            <div className="text-black p-3">
                <Row>
                    <Col md={3}></Col>
                    <Col md={6}>
                        <h3 className="text-center mt-3 mb-3">Signup</h3>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" name="name" placeholder="Enter Name" value={user.name} onChange={handleInputs} />
                                {errors.name && <Form.Text className="text-danger">{errors.name}</Form.Text>}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" name="email" placeholder="Enter Email" value={user.email} onChange={handleInputs} />
                                {errors.email && <Form.Text className="text-danger">{errors.email}</Form.Text>}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" name="password" placeholder="*******" value={user.password} onChange={handleInputs} />
                                {errors.password && <Form.Text className="text-danger">{errors.password}</Form.Text>}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formCPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="password" name="c_password" placeholder="*******" value={user.c_password} onChange={handleInputs} />
                                {errors.c_password && <Form.Text className="text-danger">{errors.c_password}</Form.Text>}
                            </Form.Group>
                            <div className="text-center mt-3">
                                <Button variant="primary" type="submit" disabled={loading}>
                                    {loading ? 'Loading...' : 'Sign Up'}
                                </Button>
                            </div>
                        </Form>
                        <div className="text-center mt-3">
                            <p>Have an Account? 
                            <Link to="/login" className="text-decoration-none">
                            Login
                             </Link>
                            </p>
                        </div>
                    </Col>
                    <Col md={3}></Col>
                </Row>
            </div>
        </Container>
    );
}
