import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Pages/Home';
import Discussions from './Components/Pages/Discussions';
import Clubs from './Components/Pages/Clubs';
import Matches from './Components/Pages/Matches';
import Login from './Components/Pages/Login';
import Signup from './Components/Pages/Signup';
import Header from './Components/common/Header';
import Forumcategory from './Components/Pages/Forumcategory';

function App() {
  const [user, setUser] = useState({
    token: localStorage.getItem('token'),
    userId: localStorage.getItem('user_id'),
    name: localStorage.getItem('name'),
    email: localStorage.getItem('email'),
    tokenType: localStorage.getItem('token_type'),
  });

  const handleLogin = (data) => {
    console.log(data.accessToken);
    localStorage.setItem('token', data.accessToken);
    localStorage.setItem('user_id', data.data.user_id);
    localStorage.setItem('name', data.data.name);
    localStorage.setItem('email', data.data.email);
    localStorage.setItem('token_type', data.token_type);
    setUser({
      token: data.accessToken,
      userId: data.data.user_id,
      name: data.data.name,
      email: data.data.email,
      tokenType: data.data.token_type,
    });
  };
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('token_type');
    setUser({
      token: null,
      userId: null,
      name: null,
      email: null,
      tokenType: null,
    });
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUserId = localStorage.getItem('user_id');
    const storedName = localStorage.getItem('name');
    const storedEmail = localStorage.getItem('email');
    const storedTokenType = localStorage.getItem('token_type');

    if (storedToken) {
      setUser({
        token: storedToken,
        userId: storedUserId,
        name: storedName,
        email: storedEmail,
        tokenType: storedTokenType,
      });
    }
  }, []);

  return (
    <Router>
      <Header user={user} handleLogout={handleLogout} />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/matches" element={<Matches />} />
        <Route path="/clubs" element={<Clubs />} />
        <Route path="/discussions/:catId" element={<Discussions />} />
        <Route path='/forum-category' element={<Forumcategory/>}/>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/signup" element={<Signup />} />
      </Routes>
    </Router>
  );
}

export default App;
