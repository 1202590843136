import React from 'react'
import Signupform from './Signupform'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import Copyright from '../../common/Copyright'

export default function index() {
    return (
        <>
          
            <Signupform />
            <Footer />
            <Copyright />
        </>
    )
}