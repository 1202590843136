import React, { useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../Logo';
import './header.css'

export default function Header({ user, handleLogout }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    let searchTimer = null;

    const handleSearchChange = (e) => {
        const { value } = e.target;
        setSearchQuery(value);

        if (searchTimer) {
            clearTimeout(searchTimer);
        }
        searchTimer = setTimeout(() => {
            fetchSearchResults(value);
        }, 1000); 
    };

    const fetchSearchResults = async (query) => {
        try {
            const response = await fetch(`https://admin.enigmamotions.com/api/auth/get-match-list?query=${query}`);
            const data = await response.json();
            if (data && data.data) {
                setSearchResults(data.data); 
            } else {
                setSearchResults([]); 
            }
        } catch (error) {
            console.error('Error fetching search results:', error);
            setSearchResults([]); 
        }
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        fetchSearchResults(searchQuery);
    };

    return (
        <Navbar expand="lg" className="navbar-custom">
            <Container>
                <LinkContainer to="/">
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="me-auto my-2 my-lg-0 navbar-nav-md-scroll">
                        <LinkContainer to="/home">
                            <Nav.Link>Home</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/matches">
                            <Nav.Link>Matches</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/clubs">
                            <Nav.Link>Clubs</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to='/forum-category'>
                            <Nav.Link>Discussions</Nav.Link>
                        </LinkContainer>
                    </Nav>
                    <Form className="d-flex me-2 " onSubmit={handleSearchSubmit}>
                        <Form.Control
                            type="search"
                            placeholder="Search Match"
                            className="me-2"
                            aria-label="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                        {/* Optionally add a button to trigger search */}
                        {/* <Button type="submit">Search</Button> */}
                    </Form>
                    {/* Display search results */}
                    {searchResults.length > 0 && (
                        <ul className="list-group mt-2">
                            {searchResults.map((result, index) => (
                                <li key={index} className="list-group-item">
                                    <LinkContainer to={`/video/${result.id}`}>
                                        <div style={{ width: '100px', height: '150px',  }}>
                                            <img 
                                                src={result.video_thumbnail} 
                                                alt={result.video_title} 
                                                style={{ width: '100%', height: 'auto' }} 
                                            />
                                            <span>{result.video_title}</span>
                                        </div>
                                    </LinkContainer>
                                </li>
                            ))}
                        </ul>
                    )}
                    {user.token ? (
                        <Button className="grey-btn" onClick={handleLogout}>
                            Logout
                        </Button>
                    ) : (
                        <LinkContainer to="/login">
                            <Button className="grey-btn">Login / Register</Button>
                        </LinkContainer>
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
