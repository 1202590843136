import React from 'react'
import Header from '../../common/Header'
import Livematch from './Livematch'
import Highlight from './Highlight'
import Toppicks from './Toppicks'
import Copyright from '../../common/Copyright'
import Footer from '../../common/Footer'
import Livecompletematch from './Livecompletematch'
export default function Index() {
    return (
        <>
            <Livematch />
            <Livecompletematch/>
            <Highlight />
            <Toppicks />
            <Footer />
            <Copyright />
        </>
    )
}
