import React from 'react'
import Matchestab from './Matchestab'

import Header from '../../common/Header'
import Footer from '../../common/Footer'
import Copyright from '../../common/Copyright'


export default function index() {
    return (
        <>
            <Matchestab />
            <Footer />
            <Copyright />
        </>
    )
}
