import React from 'react'
import Clubcontent from './Clubcontent'
import Footer from '../../common/Footer'
import Highlights from '../Home/Highlight'
import Toppicks from '../Home/Toppicks'
import Copyright from '../../common/Copyright'

export default function index() {
    return (
        <>
            <Clubcontent />
            <Highlights />
            <Toppicks />
            <Footer />
            <Copyright />
        </>
    )
}
